.grid-container{
    display: grid;
    grid-template-columns: repeat(var(--column-count), minmax(0, 1fr));
    gap:3rem;
  }
  
  .grid-container.xs{
    --column-count:2;
    /* width:50%; */
    /* padding-left: 25%; */
  }
  
  .grid-container.sm{
    --column-count:2
  }
  
  .grid-container.md{
    --column-count:3
  }
  
  .grid-container.lg{
    --column-count:4
  }
  
  .grid-container.xl{
    --column-count:5
  }
  
  .grid-container.xxl{
    --column-count:6
  }
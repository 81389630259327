#searchbar{
    background-color: rgba(2,2,2,0.2);
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    padding: .5em;
    border: 0;
    margin: 0;
    border-radius: 3px;
    text-indent: 0.01px;
    text-overflow: '';
}

#searchbar::placeholder{
    color: #75006a
}

/* remove searchbar decorations - i.e. */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* remove searchbar decorations - chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
/*
wave color=2A003E
box color750777
portbox color F5F5F5
*/

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;600;700&display=swap');

.main-container {
  background-color: #54004C;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  position: relative;
}

.wave-container {
  position:absolute;
  width:100%;
  bottom:0;
  max-height: 20vh;
  overflow: hidden;
}

.wave {
  width:100%;
  height:100%;
  user-drag: none;
  overflow: 'hidden';
  vertical-align: bottom;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.title {
  position: absolute;
  z-index: 2;
  top:0;
  color:#FFFEEF;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 48px;
  text-align: center;
}

.title.nav {
  z-index: 1;
  padding-top:120px;
  font-size: 24px;
  font-weight: 300;
}

#footer {
  color:#FFFEEF;
  font-family: 'Roboto Mono', monospace;
  font-weight: 300;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left:20px;
  padding-bottom:10px;
  text-align: center;
}
.card{
    overflow:hidden;
    background-color: rgba(40,40,40,0.5);
    box-shadow: 5px 5px 10px rgba(0,0,0,0.2);
    border-radius:4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

/* tbd: figure out why transform on hover breaks the modal overlay */
.card:hover{
    /* transform:scale(1.03); */
}

.cardtitle{
    color: ivory;
    font-size:10px;
    padding:10px;
    font-weight:300;
    font-family: 'Roboto Mono', monospace;
}
.description{
    color: ivory;
}

#cardicon{
    width:100%;
    /* padding-top: 20px; */
    display:block;
}

/* 
.card-lg{
    overflow:hidden;
    box-shadow: 10px 2px 20px gray;
}
.card-xl{
    overflow:hidden;
    box-shadow: 10px 2px 20px gray;
}
.card-xxl{
    overflow:hidden;
    box-shadow: 10px 2px 20px gray;
} */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.pagename {
    z-index: 1;
    padding-top:120px;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    position:absolute;
    top:0;
    color:#FFFEEF;
    font-family: 'Roboto Mono', monospace;
    text-align: center;
}

#search{
    text-align: center;
    color:#FFFEEF;
    font-family: 'Roboto Mono', monospace;
    font-weight: 300;
    font-size: 18px;
    position:absolute;
    top:0;
    padding-top:200px;
    z-index: 1;
}

.spacer {
    padding-bottom:50px;
}

#content-wrap{
    padding-bottom: 2.5rem; 
    max-height: 60vh;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

#content-wrap::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.modal,.overlay {
    width:100vw;
    height:100vh;
    top:0;
    left:0;
    right:0;
    bottom:0;
    position:fixed;
    z-index: 4;

}

.overlay{
    background: rgba(49,49,49,0.7)
}

.modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    max-width: 900px;
    max-height: 600px;
    min-width: 300px;
    min-height: 200px;
}

.modal-image {
   width:100%;
}
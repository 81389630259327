* {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.choice-container {
    width:auto;
    display: grid;
    /* background-color: red; */
    grid-template-columns: repeat(3, minmax(0, 1fr));;
    gap: 3rem;
    /* padding-left:3rem; */
  }
  
.choice {
   
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    user-drag: none;
    overflow: 'hidden';
    vertical-align: bottom;

}

.choice-label {
    text-align: center;
    font-family: 'Roboto Mono', monospace;
    font-weight: 300;
    font-size: 20px;
    color:#FFFEEF;
}

.choice-pic {
    text-align: center;
    width:65%;
}


.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

}

.pagename {
    z-index: 1;
    padding-top:120px;
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    position:absolute;
    top:0;
    color:#FFFEEF;
    font-family: 'Roboto Mono', monospace;
    text-align: center;
}

.scrollable {
  
}